'use client';

import styled from 'styled-components';

import { Stack } from '@whiteaway/ui';

import { AbsoluteCenter, ErrorPage, ErrorPageButton, ErrorPageText } from '@/components';

/**
 * The `RootError` component is used to display an error message in the root of the application.
 */
export const AppError: React.FC = () => {
  return (
    <AppErrorRoot>
      <AbsoluteCenter>
        <ErrorPage>
          <ErrorPageText />

          <ErrorPageButton />
        </ErrorPage>
      </AbsoluteCenter>
    </AppErrorRoot>
  );
};

AppError.displayName = 'AppError';

export default AppError;

const AppErrorRoot = styled(Stack)`
  height: 100vh;
`;
